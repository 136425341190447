<template>
  <footer class="content-footer footer bg-footer-theme">
    <div
      class="container-xxl d-flex flex-wrap justify-content-between py-2 flex-md-row flex-column"
    >
      <div class="mb-2 mb-md-0">
      </div>
      <div>
        © 2023, Powerd ❤️ by -
        <a
          href="https://phicsart.com"
          target="_blank"
          class="footer-link fw-bolder"
          >PhicsArt</a
        >
      </div>
    </div>
  </footer>
</template>

