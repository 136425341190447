<template>  
  <span class="alert alert-danger" v-if="successReport==true">Done</span>  
  <form name="" @submit.prevent="handleSubmit">
    <div class="row">
      <!-- Basic Layout -->
      <div class="col-md-8 col-sm-12">
        <div class="card mb-4">
          <div class="card-body">
              <div class="row mb-3">
                <label class="col-sm-12 col-form-label" for="basic-icon-default-fullname">Name</label>
                <div class="col-sm-12">
                  <div class="input-group input-group-merge">
                    <span
                      id="basic-icon-default-fullname2"
                      class="input-group-text"
                      ><i class="bx bx-buildings"></i
                    ></span>
                    <input
                      type="text"
                      class="form-control"
                      id="basic-icon-default-fullname"
                      placeholder="Name!"
                      v-model="formData.name"
                      required
                    />
                  </div>
                </div>
              </div>
              <div class="row mb-3">
                <label class="col-sm-12 col-form-label" for="basic-icon-default-fullname">Title</label>
                <div class="col-sm-12">
                  <div class="input-group input-group-merge">
                    <span
                      id="basic-icon-default-fullname2"
                      class="input-group-text"
                      ><i class="bx bx-buildings"></i
                    ></span>
                    <input
                      type="text"
                      class="form-control"
                      id="basic-icon-default-fullname"
                      placeholder="Title!"
                      v-model="formData.title"
                      required
                    />
                  </div>
                </div>
              </div>
              <div class="row mb-3">
                <label
                  class="col-sm-12 col-form-label"
                  for="basic-icon-default-company"
                  >Ulr/Slug
                </label>
                <div class="col-sm-12">
                  <div class="input-group input-group-merge">
                    <span
                      id="basic-icon-default-company2"
                      class="input-group-text"
                      ><i class="bx bx-buildings"></i
                    ></span>
                    <input
                      type="text"
                      id="basic-icon-default-company"
                      class="form-control"
                      placeholder="Page alias."
                      v-model="formData.slug"
                    />
                  </div>
                </div>
              </div>
              <!-- <CkEditor></CkEditor> -->
              <div class="row mb-3">
                <label
                  class="col-sm-12 col-form-label"
                  for="basic-icon-default-company"
                  >Content</label
                >
                <div class="col-sm-12">
                    <textarea 
                      v-model="formData.description" 
                      rows="10"
                      class="form-control"
                      placeholder="Page content !"
                    ></textarea>
                </div>
              </div>
          </div>
        </div>
      </div>
      <!-- 8 end of col -->
      <div class="col-md-4 col-sm-12">
        <div class="card mb-4">
          <div class="card-body">
            <div class="page-image">
              <img
                src="https://www.imageeditexpert.com/uploads/images/about/about.png"
                alt="n/a"
                class="slider-setting-img img-fluid"
              />
            </div>
            <div class="mb-3">
              <label for="formFile" class="form-label">Image Choose</label>
              <input class="form-control" type="file" id="formFile" />
            </div>
          </div>
        </div>
        <div class="card">
            <button class="btn btn-primary" type="submit">Save & Publish</button>
        </div>
      </div>
      <!-- 4 end of col -->
    </div>
  </form>
</template>
<script lang="ts">
import axios from 'axios';
import { defineComponent } from 'vue';
import { useStore } from 'vuex';

export default defineComponent({
  name: "PageForm", // Rename the component to a multi-word name
  data() {
    const store = useStore();
    const token = store.state.auth.token || localStorage.getItem('token');
    return {
      store,
      token,
      pageId: '',
      siteInfoData: [],
      successReport: false,
      failedReoprt: false,
      formData: {
        name: "",
        title: "",
        thumb: "",
        banner_img: "",
        description: "",
        slug: "",
        page_template: "default",
        tags: "",
        meta_key: "",
        meta_description: "",
        status: 0
      }
    }
  },
  props: {
    isDataTrue: Boolean
  },

  watch: {
    '$route.params.id': {
      // immediate: true,
      handler(newVal) {
        console.log('_id900_', newVal);
        if (newVal) {
          this.fetchData(newVal);
          this.pageId = newVal;
        }
      },
    },

  },

  methods: {
    async handleSubmit() {
      console.log("_my_form_data", this.formData);
      try {
        if (this.pageId) {
          const response = await (axios as any).put(
            'https://api.aaatradeinternational.com/api/page/' + this.pageId,
            this.formData, 
            {
              headers: {
                Authorization: `Bearer ${this.token}`,
              }
            }
          );
          if
          (response.status == 204) {
            console.log();
            this.successReport = true;
            setTimeout(() => { this.successReport = false; }, 3000);
          }
        } else {
          const response = await (axios as any).post('https://api.aaatradeinternational.com/api/page', this.formData);
          if (response.status == 201) {
            // console.log('_save_success', response);
            this.formData = {
              name: "",
              title: "",
              thumb: "",
              banner_img: "",
              description: "",
              slug: "",
              page_template: "default",
              tags: "",
              meta_key: "",
              meta_description: "",
              status: 0
            };

            this.successReport = true;
            setTimeout(() => { this.successReport = false; }, 3000);
          }
        }
      } catch (error) {
        console.error(error);
      }
      // this.successReport = false;
    },
    async fetchData(id: number) {
      try {
        if (!this.token) {
          throw new Error('Token not found');
        }
        const response = await (axios as any).get(`https://api.aaatradeinternational.com/api/page/${id}`, {
          headers: {
            Authorization: `Bearer ${this.token}`,
          },
        });
        console.log('_page_data_by_ID_', response);
        if (response.status === 200) {
          const data = response.data;
          this.formData = data.data;
        }
      } catch (error) {
        console.error(error);
      }
    },
  },
    mounted() {
    // Set isLoading to true when the component is mounted
    //this.handleSubmit();
  }
});
</script>